import { CustomTextarea } from "../../components/customTextarea";
import { FC } from "react";
import { CellContext } from "@tanstack/react-table";
import { IPrice } from "../../@types/graphql";
import { useReactiveVar } from "@apollo/client";
import { pricesChangedRows } from "../../store/prices.store";

export const CommentCell: FC<CellContext<IPrice, unknown>> = (d) => {
  const id = d.row.original.id;
  const editRows = useReactiveVar(pricesChangedRows);

  return (
    <>
      <CustomTextarea
        value={editRows?.[id]?.comment ?? `${d.getValue() || ""}`}
        rows={5}
        onChange={(e) => {
          const prev = {
            steps: d.row.original.steps,
            sku: d.row.original.sku,
            id: d.row.original.id,
            markups: d.row.original.markups,
            price: d.row.original.price,
            ...(editRows[id] || {}),
          };
          pricesChangedRows({
            ...editRows,
            [id]: { ...prev, comment: e.target.value },
          });
        }}
      />
    </>
  );
};
